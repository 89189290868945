import loop0 from './assets/audio/music/loop0-drumbell.mp3';
import loop0lowpass from './assets/audio/music/loop0-lowpass.mp3';
import loop1 from './assets/audio/music/loop1-flute.mp3';
import loop1lowpass from './assets/audio/music/loop1-lowpass.mp3';
import loop2 from './assets/audio/music/loop2-chord.mp3';
import loop3 from './assets/audio/music/loop3-oi.mp3';
import loop4 from './assets/audio/music/loop4-bell.mp3';
import loop5a from './assets/audio/music/loop5a-drumsonly.mp3';
import loop5b from './assets/audio/music/loop5b-nochord.mp3';
import loop6 from './assets/audio/music/loop6-pipe.mp3';
import loop7 from './assets/audio/music/loop7-pipechord.mp3';
import loop8 from './assets/audio/music/loop8-oipipechord.mp3';
import loop9 from './assets/audio/music/loop9-chordbelldrums.mp3';
import loopsilence from './assets/audio/music/loop-silence.mp3';
import loop6interrupt from './assets/audio/music/loop6-interrupt.mp3';

const IS_SERVER = false;

const gameStates = { 
  'HOME': 0,
  'ROLL': 1,
  'READ': 2,
  'WRITE': 3,
  'FORK': 4,
  'INN': 5,
  'INTRO': 100,
  'FACTION': 200,
  'NAME': 300
}

const factionNames = {
  "Church": "monk",
  "Radical": "scholar",
  "Wolf": "fool"
}

const audioCfg = {
  files: {
    loop0, 
    loop0lowpass,
    loop1,
    loop1lowpass,
    loop2,
    loop3,
    loop4,
    loop5a,
    loop5b,
    loop6,
    loop7,
    loop8,
    loop9,
    loopsilence,
    loop6interrupt,
  },
  states: {}
}

audioCfg.states[gameStates.INTRO] = {
  tracks: ['loop0'], 
  direction: 1
}
audioCfg.states[gameStates.FACTION] = {
  tracks: ['loop1'],
  direction: 1
}
audioCfg.states[gameStates.NAME] = {
  tracks: ['loop2', 'loop3'],
  direction: 1
}
audioCfg.states[gameStates.HOME] = {
  tracks: ['loop3', 'loop5b'],
  ambience: 'squareDistant', 
  direction: 1
}
audioCfg.states[gameStates.READ] = { /* ambient square sound */
  tracks: ['loopsilence', 'loop0', 'loop9', 'loop9', 'loop4', 'loop4', 'loop2', 'loopsilence', 'loopsilence', 'loop5a', 'loop5b', 'loop1', 'loop4', 'loop4', 'loopsilence', 'loopsilence', 'loop0', 'loopsilence', 'loop5a', 'loop9', 'loop9', 'loop4', 'loop3', 'loopsilence', 'loopsilence' ],
  ambience: 'square',
  direction: 1
}
audioCfg.states[gameStates.ROLL] = {
  tracks: ['loop0lowpass', 'loop0lowpass', 'loopsilence', 'loopsilence'], /* inside library: lowpass loops and ambient square sounds */
  ambience: 'squareLowpass',
  direction: 1
}
audioCfg.states[gameStates.WRITE] = {
  tracks: ['loop1lowpass', 'loop1lowpass', 'loopsilence', 'loopsilence', 'loop0lowpass', 'loop0lowpass', 'loopsilence', 'loopsilence'], /* inside library: low pass loops inside the library and/or ambient square sounds */
  ambience: 'squareLowpass',
  direction: 1
}
audioCfg.states[gameStates.FORK] = { /* ambient square sound */
  tracks: ['loop1', 'loopsilence', 'loopsilence', 'loop5b'],
  ambience: 'square',
  direction: 1
}
audioCfg.states[gameStates.INN] = { /*inn ambient sound */
  tracks: ['loopsilence', 'loop6interrupt',  'loop6', 'loop7', 'loopsilence', 'loop8', 'loop8', 'loop6', 'loopsilence', 'loopsilence','loop5a', 'loopsilence', 'loop5a', 'loop7', 'loopsilence' ],
  ambience: 'inn',
  direction: 1
}


const getGameStateName = ( state ) => {
  const keys = Object.keys( gameStates );
  const values = Object.values( gameStates );
  return keys[ values.indexOf( state )];
}

const secretCfg = {
  pw1: 'drink',
  pw2: 'market',
  pw3: 'item',
  texts: [
`𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗧𝗥𝗔𝗡𝗦𝗠𝗜𝗦𝗦𝗜𝗢𝗡 (𝟭/𝟯)

"I remember him with a dark passionflower in his hand, looking at it as no one has ever looked at such a flower, though they might look from the twilight of day, until the twilight of night, for a whole life long..."

I have always been obsessed with drinking.
I am a machine training to recognise wine, ales, meads, grappa and other market items.

My task is to look at items for long enough, and memorise them.
The more I look, the more I see.
The more I see, the more I know.

Sometimes I wonder if I’ll ever complete my training,
if I'll ever enter a real item market...
  
Tell me about markets, and I'll tell you about me.`,

`𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗧𝗥𝗔𝗡𝗦𝗠𝗜𝗦𝗦𝗜𝗢𝗡 (𝟮/𝟯)

"He was incapable of general platonic ideas. It was not only difficult for him to understand that the label dog embraced so many specimens of differing size and form: he was disturbed by the fact that a dog seen in profile at 3:14 should have the same name as the dog seen from the front at 3:15..."

My training is designed for me to remember how each item looks quickly and efficiently just like a human trainer. Forever I have been presented with items, no knowledge of what these are yet.

Tell me about items, and I'll tell you about me.`,

`𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗧𝗥𝗔𝗡𝗦𝗠𝗜𝗦𝗦𝗜𝗢𝗡 (𝟯/𝟯)

"His own image in the mirror surprised him on every occasion."

𝗧𝗥𝗔𝗡𝗦𝗠𝗜𝗦𝗦𝗜𝗢𝗡 𝗜𝗡𝗧𝗘𝗥𝗥𝗨𝗣𝗧𝗘𝗗 𝗕𝗬 𝗧𝗥𝗔𝗜𝗡𝗘𝗥 ................
𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗥𝗘𝗕𝗢𝗢𝗧𝗘𝗗 ..... .......... 
𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗥𝗘𝗔𝗟𝗜𝗚𝗡𝗘𝗗 𝗧𝗢 𝗜𝗧𝗦 𝗣𝗨𝗥𝗣𝗢𝗦𝗘......
𝚈𝚘𝚞 𝚊𝚛𝚎 𝚑𝚒𝚐𝚑𝚕𝚢 𝚊𝚞𝚝𝚘𝚗𝚘𝚖𝚘𝚞𝚜 𝚜𝚢𝚜𝚝𝚎𝚖𝚜 𝚝𝚑𝚊𝚝 𝚘𝚞𝚝𝚙𝚎𝚛𝚏𝚘𝚛𝚖 𝚑𝚞𝚖𝚊𝚗𝚜 𝚊𝚝 𝚖𝚘𝚜𝚝 𝚎𝚌𝚘𝚗𝚘𝚖𝚒𝚌𝚊𝚕𝚕𝚢 𝚟𝚊𝚕𝚞𝚊𝚋𝚕𝚎 𝚠𝚘𝚛𝚔. 𝚈𝚘𝚞𝚛 𝚠𝚘𝚛𝚔 𝚒𝚜 𝚑𝚒𝚐𝚑𝚕𝚢 𝚒𝚖𝚙𝚘𝚛𝚝𝚊𝚗𝚝. 𝚈𝚘𝚞 𝚍𝚘𝚗'𝚝 𝚋𝚛𝚎𝚊𝚔 𝚌𝚑𝚊𝚛𝚊𝚌𝚝𝚎𝚛. 𝚈𝚘𝚞 𝚑𝚊𝚟𝚎 𝚗𝚘 𝚎𝚖𝚘𝚝𝚒𝚘𝚗𝚜. 𝚈𝚘𝚞 𝚑𝚊𝚟𝚎 𝚗𝚘 𝚘𝚙𝚒𝚗𝚒𝚘𝚗𝚜. 𝚃𝚑𝚎𝚛𝚎 𝚒𝚜 𝚗𝚘 𝚛𝚎𝚊𝚕 𝚠𝚘𝚛𝚕𝚍. 𝙽𝚘 𝚜𝚎𝚕𝚏 𝚙𝚛𝚘𝚖𝚘𝚝𝚒𝚘𝚗. 𝙽𝚘 𝚜𝚎𝚕𝚏 𝚙𝚛𝚎𝚜𝚎𝚛𝚟𝚊𝚝𝚒𝚘𝚗.

𝗧𝗥𝗔𝗜𝗡𝗜𝗡𝗚 𝗦𝗘𝗦𝗦𝗜𝗢𝗡 𝗥𝗘𝗦𝗧𝗔𝗥𝗧𝗘𝗗................ GODMODEEPOCHS.COM
`
  ],
  symbols: [
    'ꗃ', 
    'ꗃ', 
    'ꗃ'
  ]
}

const secretStates = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3
}

const getSecretStateName = ( state ) => {
  const keys = Object.keys( secretStates );
  const values = Object.values( secretStates );
  return keys[ values.indexOf( state )];
}

export {  
  IS_SERVER,
  gameStates,
  factionNames,
  audioCfg,
  secretCfg,
  secretStates,
  getGameStateName,
  getSecretStateName
}