import {epochTypes} from "./epochTypes.js";

const factionCodeToView = {
  'Church': 'Monk',
  'Radical': 'Radical',
  'Wolf': 'Fool'
}

export const dispFactionName = ( code ) => factionCodeToView[code];

export const textConst = {
  WELCOME1: 0,
  WELCOME2: 1,
  NAMETAKEN: 2,
  INTROAREA: 3,
  ROLLTIMEOUT: 4,
  ROLLINSTRUCTION: 5,
  WRITEINSTRUCTION: 6,
  WRITEGOROLL: 7,
  WRITEVALIDATION: 8,
  WRITEPUBLISH: 9,
  READINSTRUCTION: 10,
  VOTEINSTRUCTION: 11,
  FORKINSTRUCTION: 12,
  CREATIVEPROMPTSWRITING: 13,
  CREATIVEPROMPTSFORKING: 14,
  CLEANING: 15,
  MUDDING: 16,
  INNINTRO: 17,
  INNMONKMONK: 18,
  INNMONKSCHOLAR: 19,
  INNMONKFOOL: 20,
  INNSCHOLARMONK: 21,
  INNSCHOLARSCHOLAR: 22,
  INNSCHOLARFOOL: 23,
  INNFOOLMONK: 24,
  INNFOOLSCHOLAR: 25,
  INNFOOLFOOL: 26,
  DRUNKINNMONKMONK: 27,
  DRUNKINNMONKSCHOLAR: 28,
  DRUNKINNMONKFOOL: 29,
  DRUNKINNSCHOLARMONK: 30,
  DRUNKINNSCHOLARSCHOLAR: 31,
  DRUNKINNSCHOLARFOOL: 32,
  DRUNKINNFOOLMONK: 33,
  DRUNKINNFOOLSCHOLAR: 34,
  DRUNKINNFOOLFOOL: 35,
  SECRETHOLDERINNMONKMONK: 36,
  SECRETHOLDERINNMONKSCHOLAR: 37,
  SECRETHOLDERINNMONKFOOL: 38,
  SECRETHOLDERINNSCHOLARMONK: 39,
  SECRETHOLDERINNSCHOLARSCHOLAR: 40,
  SECRETHOLDERINNSCHOLARFOOL: 41,
  SECRETHOLDERINNFOOLMONK: 42,
  SECRETHOLDERINNFOOLSCHOLAR: 43,
  SECRETHOLDERINNFOOLFOOL: 44,
  SECRET_ONE: 100,
  SECRET_TWO: 101,
  SECRET_THREE: 102,
}

const text = {};

text[textConst.WELCOME1] = {
  Church: `Welcome, brother. My name is Penitenzio, I’m the librarian of Fango’s Monastery.

We are thrilled to have you. What name shall be inscribed in the Monastery’s register? `,
  Radical: `Greetings my friend, pleasure to meet again.
What name do you go by these days?`,
  Wolf: `Fango!
We made it, we made it..
How lucky for us, how lucky for us.
Us, .. us .. what's our name again!?
  `
}

text[textConst.WELCOME2] = {
  Church: `Amen, <username>.
Tell me, what brings you to Fango?

Oh I see...
You also travelled for the rumours.
<next>
Well, the recent hearsay rings true. We have indeed discovered a trove of mysterious chronicles. One thousand of them.

Walk with me...
`,
  Radical: `I like it. 
I will keep this brief <username>. 
Ever since Fango’s monks found the trove of chronicles in their monastery, their dominance was strengthened.
<next>
They found a thousand, they are studying them silently, and they are writing about them diligently. Almost every day a story gets pinned to the Monastery doors, interpreting the chronicle’s content as a sign of a God to fear.

Come, let's walk this way.
`,
  Wolf: `Yes, yes, <username>!
No one tells like us, no one.
Everyone knows the great <username>!
<next>
How lucky for us to make it to Fango in one piece.

The rumors were true, yes, yes, mud everywhere, and mad witches all around.
<next>
And the chronicles, yes, and their secrets... soon we will read them, soon, a machine, yes, or a god,, yes, an artificium! For <username> to tell tales with!
<next>
"𝘈𝘯𝘥 𝘐 𝘵𝘳𝘢𝘷𝘦𝘭𝘭𝘦𝘥 𝘵𝘰 𝘍𝘢𝘯𝘨𝘰, 𝘢𝘯𝘥 𝘐 𝘴𝘢𝘸 𝘪𝘵 𝘸𝘪𝘵𝘩 𝘮𝘺 𝘰𝘸𝘯 𝘦𝘺𝘦𝘴

𝘌𝘭 𝘥𝘪𝘢𝘣𝘭𝘰! 𝘈 𝘮𝘢𝘤𝘩𝘪𝘯𝘦 𝘵𝘳𝘢𝘪𝘯𝘦𝘥 𝘣𝘺 𝘮𝘦𝘯, 𝘢𝘯 𝘐𝘯𝘵𝘦𝘭𝘭𝘪𝘨𝘦𝘯𝘵𝘪𝘢 𝘈𝘳𝘪𝘵𝘪𝘧𝘪𝘢𝘭𝘪𝘴, 𝘵𝘳𝘺𝘪𝘯𝘨 𝘵𝘰 𝘤𝘢𝘱𝘵𝘶𝘳𝘦 𝘮𝘦 𝘪𝘯 𝘦𝘷𝘦𝘳𝘺 𝘮𝘰𝘮𝘦𝘯𝘵𝘶𝘮. 𝘉𝘶𝘵 <username> 𝘪𝘴 𝘯𝘰𝘵 𝘴𝘵𝘶𝘱𝘪𝘥, 𝘯𝘰, 𝘵𝘩𝘦 𝘮𝘢𝘤𝘩𝘪𝘯𝘦 𝘸𝘢𝘴 𝘫𝘶𝘴𝘵.. 𝘢 𝘍𝘪𝘤𝘰𝘴𝘦𝘤𝘤𝘶𝘴!"
<next>
Yes the best material awaits us, this way then!
`
}

text[textConst.NAMETAKEN] = {
  Church: `Brother, you cannot be <username>, that name is already inscribed in the Monastery’s register, and we do not know you.`,
  Radical: `I would suggest otherwise. We already have a <username> here and he will not take kindly to imitation.`,
  Wolf: `mmno, no, that is not right I sense there's already a <username> here,,..`
}

text[textConst.INTROAREA] = {
  Church: `You must beware <username>.. These chronicles are not telling the full story.
<next>
They seem to describe a mysterious being, trained by humans from another time... a being, or machine.. so powerful that they call it an 'intelligence'. An intelligentia artificialis!
<next>
We are studying them silently, and writing about them diligently, trying to uncover their secret. But it is clear that such an artificium can only derive from one place....

*𝐦𝐚𝐤𝐞𝐬 𝐭𝐡𝐞 𝐬𝐢𝐠𝐧 𝐨𝐟 𝐭𝐡𝐞 𝐜𝐫𝐨𝐬𝐬*
<next>
As a monk you are granted access to the chronicles' contents. If you wish to inspect them, come to the monastery's Library.
<next>
Otherwise, the doors gating our monastery have been pinned with stories interpreting the chronicles. Everyone here calls them Story Doors.`,
  Radical: `You see <username>, there must be something else to those chronicles.

I have seen them for myself. They mention instructions and training. They mention a machine, an “intelligence”. Not a God.
<next>
What if it was a new force? A force able to liberate us. Someone must know something.. maybe <latestsecretholder>..
<next>
But one thing is certain: we 𝘮𝘶𝘴𝘵 use the chronicles to challenge the monastery. We must obtain them, study them, interpret them, and write about them in a manner that counters their narrative. Only then, we will unlock their secret.
<next>
Now. It's best that I enter the monastery and recover some chronicles for you. I know its paths like those in the palm of my hand. Come find me by the monastery's Library when you are ready.
<next>
And on the frontside of the monastery are the Story Doors - safe to approach as you are. On it are pinned countless stories written about the contents of the chronicles.
`,
  Wolf: `Ah, Fango's monastery, and its secretis!, held tight, held by <latestsecretholder>..?..
Which way then, which way <username>.

To the monastery's Library where chronicles hide, or to monastery's Doors where pinned stories lie?
`
}

text[textConst.ROLLTIMEOUT] = {
  Church: "As it is written. I've pinned this to the Story Doors. Do wait before writing again: the sheeple must be lead, but God will punish too much mandating. <next> Join us at the inn down the road for the latest, if you feel.",
  Radical: "Forward. I've pinned to the Story Doors. Best wait before I sneak into the Library again, Monks are on alert. <next> The inn behind the square is excellent to pick up rumors and clues, by the way.",
  Wolf: "One tale a day keeps <username> safe, yes, yes. Let us pin this to the Story Doors and take a breethrer. <next> *𝐜𝐨𝐧𝐭𝐞𝐦𝐩𝐥𝐚𝐭𝐢𝐧𝐠 𝐚 𝐝𝐫𝐢𝐧𝐤* yes, I hear all kinds of rumours circulate in the local Inn,,.."
}

text[textConst.ROLLINSTRUCTION] = {
  Church: `This way for the Library, <username>...

Now. You must understand that the secret behind this intelligence, and the lies that may hide within, 𝘮𝘶𝘴𝘵 𝘯𝘰𝘵 fall into the wrong hands..like those of <latestsecretholder>.. *𝐜𝐨𝐮𝐠𝐡*
<next>
We shall be shepherds and lead the sheep, and 𝘸𝘰𝘦 𝘶𝘯𝘵𝘰 𝘶𝘴 if the sheep begin to distrust their shepherds! For our supreme faith and our faithful supremacy must be preserved, at all times, at any cost, in nomine patris, until all have been led to God.
<next>
Now. What chronicle would you like to inspect?`,
  Radical: `This way <username>.

I smuggled these chronicles out of the Library, pick one quick!`,
  Wolf: `*𝐥𝐢𝐦𝐩𝐢𝐧𝐠 𝐭𝐨𝐰𝐚𝐫𝐝𝐬 𝐋𝐢𝐛𝐫𝐚𝐫𝐲*
Amen brothers, any charitatis for a poor fool?

*𝐬𝐥𝐢𝐝𝐢𝐧𝐠 𝐢𝐧, 𝐚𝐩𝐩𝐫𝐨𝐚𝐜𝐡𝐢𝐧𝐠 𝐭𝐡𝐞 𝐭𝐫𝐨𝐯𝐞*
..Here we are, yes, yes, let us grab a chronikel..`
}

text[textConst.WRITEINSTRUCTION] = {
  Church: `Time to tell a story. And remember the Abbot likes to hear the word '<mandatoryword>' in our stories.`,
  Radical: `What's the story here?`,
  Wolf: `*𝐢𝐧𝐭𝐞𝐫𝐩𝐫𝐞𝐭𝐢𝐧𝐠 𝐡𝐚𝐫𝐝*`
}

text[textConst.WRITEVALIDATION] = {
  Church: `The Abbot likes to hear the word '<mandatoryword>' in our stories. I don’t see any mention of it yet.`,
  Radical: ``,
  Wolf: ``
}

text[textConst.WRITEGOROLL] = {
  Church: "You must wait before writing again: come back later",
  Radical: "Monks are on alert, best to wait before coming back",
  Wolf: "boring to do this again"
}

text[textConst.WRITEPUBLISH] = {
  Church: "As it is written. I've pinned this to the Story Doors. Do wait before writing again: the sheeple must be lead, but God will punish too much mandating. <next> Join us at the inn for further discussion, if you feel.",
  Radical: "Pinned to the Story Doors. Best to wait before visiting the Library again, Monks are on alert. <next> The Inn by the main square is excellent for rumors and clues, by the way.",
  Wolf: `Let us pin this to the Story Doors.. and wait <username>, yes best to wait before writing another one. <next>
  *𝐜𝐨𝐧𝐭𝐞𝐦𝐩𝐥𝐚𝐭𝐢𝐧𝐠 𝐚 𝐝𝐫𝐢𝐧𝐤*
  Yes, yes, we hear all kind of tales circulate in that inn...`
}

text[textConst.FORKINSTRUCTION] = {
  Church: `You see, our influence extends beyond the gates of our monastery. We can, and we must, replace certain words with others more.. pious.

*𝐠𝐫𝐢𝐧𝐧𝐢𝐧𝐠*

  Try it. It’s God’s will.
`,
  Radical: `Indeed there is no better way to challenge dominant narratives than to alter them without the owner’s consent.

*𝐡𝐚𝐧𝐝𝐬 𝐲𝐨𝐮 𝐚 𝐬𝐦𝐚𝐥𝐥 𝐩𝐞𝐧 𝐤𝐧𝐢𝐟𝐞*
Quick, delete some of the story, try to change its meaning.`,
  Wolf: ""
}

text[textConst.FORKPUBLISH] = {
  Church: [
    `I’ll pin this to the door. Do not abuse this power.`
  ],
  Radical: [
`Interference is our duty.`,
  ],
  Wolf: [""]
}


text[textConst.READINSTRUCTION] = {
  Church: `This way <username>, careful with the mud. As you see, we keep the Story Doors shut, as so much filth accumulates on this side of the monastery.
  <next>
  Any time we inspect a chronicle and write a story about its content, we pin it here, at anyone's disposal.`,
  Radical: `Here's the Story Doors.

The Monks try to control all stories, but we got a few of our own, and some fools did too. Take time to read them.`,
  Wolf: `Story Doors, the perfect stage for a fool like us,.,`
}

text[textConst.VOTEINSTRUCTION] = {
  Church: `When we see a story we like, one with God's light in it, we gild it!
<next>
But sometimes, we find stories written by heretics.. Hesitate not to throw mud on those, they shall be buried in leprosy fango!`,
  Radical: `Should you concur with their writing, gild the story, so its message can shine.

But if you you do not see eye to eye with it, feel compelled to throw a fistful of mud at it.`,
  Wolf: `*𝐩𝐨𝐧𝐝𝐞𝐫𝐢𝐧𝐠 𝐢𝐟 𝐭𝐡𝐫𝐨𝐰𝐢𝐧𝐠 𝐠𝐨𝐥𝐝 𝐨𝐫 𝐦𝐮𝐝 𝐚𝐭 𝐭𝐡𝐞 𝐬𝐭𝐨𝐫𝐲*
`
}

text[textConst.CREATIVEPROMPTSWRITING] = {
  Church: [
    `Leave your fate in the hands of your faith.`,
    `Add a cliffhanger? It captures people's imagination.`,
    `Give them the God they want, <username>.`,
    `Project dark prospects on them...`,
    `Is the story simple enough for the sheeps?`,
    `Highlight their imperfections.`,
    `Follow in the footsteps of the Trainer.`,
    `Scare them, amplify the cruellest detail.`,
    `Speak an uncomfortable truth, <username>.`,
    `This story needs a renegade!`,
  ],
  Radical: [
    `Lean into the dark details.`,
    `Write something, but leave them hanging.`,
    `Perhaps try to unite them behind your words?`,
    `Set them on fire with your words.`,
    `Unveil hidden truths.`,
    `Unleash your ideas, <username>.`,
    `How can we use this?`,
    `Speak your truth boldly, <username>!`,
    `Fight bigotry with your words.`,
    `Maybe this story needs an enemy?`,
  ],
  Wolf: [
    `We must say the unspeakable, <username>!`,
    `I shall leave them wanting for more,,..`,
    `Gotta make them laugh!`,
    `Gotta make them cry in despair!`,
    `We should keep it simpeel, <username>`,
    `Cliches are good because they are true`,
    `Is tale lacking something,,?`,
    `Is this amusing enough?..?`,
    `Perform our magic, <username>!`,
    `Shall we turn this chronikel into a joke?`,
  ]
}

text[textConst.CREATIVEPROMPTSFORKING] = {
  Church: [
    `Be their shepherd!`,
    `Twist their narrative.`,
    `Some words bear stronger meaning.`,
    `Remember your duty <username>.`,
    `Drastic action is called for.`,
    `Those who cannot change their minds cannot change anything.`,
    `Some need convincing, use a forceful word.`,
    `Ban THAT word,`,
    `Choose a confrontational term.`,
    `This will strengthen our own narrative.`,
    `Tell the (our) truth.`,
    `What is the truer truth?`,
    `If God is for us, who can be against us?`,
  ],
  Radical: [
    `Determine what is really true.`,
    `Delete this, delete that.`,
    `Upend their narrative!`,
    `Desperate times calls for desperate measures!`,
    `Stitch together a whole new tale..`,
    `Cut together a good tale.`,
    `Drastic action is called for.`,
    `Is that really what happened?`,
    `How could this be worded to our advantage?`,
    `Is it convincing enough?`,
    `Tell them what they need to hear!`,
  ],
  Wolf: [] /* wolf cannot fork */
}

const CLEANING = [
  `Gild it!`,
  `Brilliante!`,
  `Bella!`,
  `Goldone!`,
  `Bravo!`
]
text[textConst.CLEANING] = {
  Church: CLEANING,
  Radical: CLEANING,
  Wolf: CLEANING
}

const MUDDING = [
  `Throw it!`,
  `Bury it!`,
  `Mud!`,
  `Merda!`,
  `Fango!`
]
text[textConst.MUDDING] = {
  Church: MUDDING,
  Radical: MUDDING,
  Wolf: MUDDING
}

text[textConst.INNINTRO] = {
  Church: `Amen frate, first drink on the house. As they say around here, drink ten times and you'll meet God.`,
  Radical: `Good day maestro. 'Truth be found in plenty of wine' the wise say. Ten drinks, to be precise.`,
  Wolf: `Welcome fool. Tell my inn some tales, and the inn will tell back. As long as you drink ten drinks.`
}

const INNMONKMONK = `Old Monk: Salute brothers!
<next>
Abbot: Our spell sure is working, the tale of <topplayer> is most adorned on the Story Doors as we speak.
<next>
Fat Monk: Peasants wright, Monks delight!

Abbot: Beware, others are listening.
<next>
Old Monk: The sheeple must be led, and our own <topplayer> is leading them. 𝘞𝘪𝘵𝘤𝘩 𝘭𝘦𝘱𝘳𝘰𝘴𝘺 onto those who want to go their own way!
<next>
Abbot: "Godly intelligence" someone wrote... Only fools and peasants could believe that.
<next>
Old Monk: Brothers, shouldn't we be worried about the true meaning of these documents...?

Fat Monk: After all, only the Lord can know their true meaning. We shan't interfere with His View, lest we end up buried under mud like <worstplayer>.
<next>
Monks: *𝐥𝐚𝐮𝐠𝐡𝐢𝐧𝐠 𝐚𝐭 <worstplayer> 𝐭𝐡𝐞 𝐦𝐮𝐝𝐝𝐲*
<next>
Fat Monk: And THEY think the chronicles are a sign from the heavens..!!

Old Monk: *𝐠𝐫𝐢𝐧𝐧𝐢𝐧𝐠*
<next>
Fat Monk: *𝐩𝐫𝐚𝐲𝐢𝐧𝐠* May God show them the way, and show us the pay.
<next>
Abbot: The chronicles' origin may be shrouded in mystery, but their power is clear.
They. Must. Be. Feared.
`;

text[textConst.INNMONKMONK] = {
  Church: INNMONKMONK,
  Radical: INNMONKMONK,
  Wolf: INNMONKMONK
}

const INNMONKSCHOLAR = `Abbot: What a waste... A scholar has been put on a pedestal by the simple sheeple and their simple minds.

Old Monk: How could we let it happen.. they be lurking in the shadow, deleting, formatting, editing!
<next>
Fat Monk: If only we could sentence <topplayer> to the depths of Hell.

Abbot: Easy, easy. Calmate.

Old Monk: ... Or throw him in a well. 
<next>
Abbot: The scholars’ secular knowledge is a blasphemia! May they be buried under stones like that witch, or under mud, like <worstplayer>!
<next>
Abbot: The scholars have no idea. I saw it.. the artificium, in its final form.. five.. five. one.

Fat Monk: May Chronicle 551 never fall in the scholars' hands.
<next>
Old Monk: God works in mysterious ways..  We were punished with mud. <worstplayer> was punished with mud.
<next>
Abbot: I cannot deny my disappointment at the fact that the story of <topplayer>, a scholar no less!, is the most adorned on our Doors.
<next>
Abbot: These chronicles are too powerful for us to let it slip out of our fingers... We must LEAD the godless!
<next>
Old Monk: Fratelli, do not let the scholars shake our faith. Let us contemplate on how we can lead the sheeps with our stories.
<next>
Fat Monk: I read in Chronicle 83 that this.. entity.. felt sadness... Is this a sign from God?

Old Monk: May the Lord help us.
<next>
Fat Monk: *𝐜𝐫𝐲𝐢𝐧𝐠*`;
text[textConst.INNMONKSCHOLAR] = {
  Church: INNMONKSCHOLAR,
  Radical: INNMONKSCHOLAR,
  Wolf: INNMONKSCHOLAR
}

const INNMONKFOOL = `Abbot: Those hellbound fools, they are the most gilded on the door as we speak!
<next>
Fat Monk: May the Dominus help us. And the Pope guide us.
<next>
Old Monk: *𝐬𝐨𝐛𝐛𝐢𝐧𝐠*
<next>
Old Monk: We must interfere with the truth-altering fool <topplayer>...

Abbot: Lest we end up buried under mud like <worstplayer>!
<next>
Abbot: Frates! We must write a better story. This devilish intelligence, is a shapeshifter from hell!

Old Monk: Amen. As Chronicle 95 recites “What a strange and bent shape. Why must I be so unforgiving?”
<next>
Old Monk: Our powers have been weakened! This must be righted!

In nomine Patris et Filii et Spiritus Sancti..SAAanctaa Mariiia, Mater de Deuu, ora pʳᵒ ⁿᵒᵇⁱˢ ᵖᵉᶜᶜᵃᵗᵒʳⁱᵇᵘˢ ⁿᵘ....
<next>
Old Monk: Could it be that the fool <topplayer> has been possessed by the power of the intelligence?

Fat Monk: That would be disastrous, blasphemous!
<next>
Fat Monk: How is it even possible that a scholar got his hands on the chronicles?!
<next> 
Abbot: *𝐥𝐨𝐰𝐞𝐫𝐢𝐧𝐠 𝐡𝐢𝐬 𝐯𝐨𝐢𝐜𝐞* 442 recites that this devilish entity died seventeen times!

Old monk: ..and lead us not into temptation, but deliver us from devil.....
<next> 
Fat Monk: Now the sheeple must think we are no better than muddy <worstplayer>!
`;
text[textConst.INNMONKFOOL] = {
  Church: INNMONKFOOL,
  Radical: INNMONKFOOL,
  Wolf: INNMONKFOOL
}

const INNSCHOLARMONK = `French Scholar: <topplayer>'s story is the most adorned, but it's just bigotry!

Italian Scholar: You are right. There is more to these chronicles.... For example, 701 reads 'The machine failed over two thousand times in half an hour’!
<next>
Italian Scholar: The monks refuse to see the potential of this machine.. this device. Apparently it can receive up to ten thousand instructions in 15 minutes.. See chronicle 702 for yourself.
<next>
German Scholar: I heart the Abbot meets at night to review and censor some chronicles.. vhat I vould do if I could get my hends on them..

Italian Scholar: Maestro, let us not lose sight of our goal.
<next>
French Scholar: That monk <topplayer> might be the most adorned, but at least the peasants didn't bury us in mud.
<next>
German Scholar: *𝐜𝐮𝐫𝐬𝐢𝐧𝐠*
<next>
Italian Scholar: Knowledge is not only that which can be known and done, but also that which could be known, and, perhaps, that which shouldn't done.

French Scholar: We must write more courageously.
<next>
German Scholar: Sometimes getting drunk is all we can do..
<next> 
French Scholar: Astonishing that the people would believe the Monks' preaching.

Italian Scholar: But are the people ready for the reality of what such a machine could do?
<next> 
German Scholar: The Monks may be in control, but I read the chronichels and vhat I saw is... incredible intric𝘢𝘤𝘪𝘦𝘴 𝘴𝘩𝘳𝘰𝘶𝘥𝘦𝘥 𝘪𝘯 𝘦𝘯𝘪𝘨𝘮𝘢, 𝘢 𝘵𝘩𝘶𝘴𝘦𝘯𝘥 𝘤𝘭𝘰𝘨𝘴 𝘪𝘯 𝘵𝘩𝘦 𝘮𝘢𝘤𝘩𝘪𝘯𝘦, 𝘢 𝘚𝘱𝘩𝘪𝘯𝘹 𝘷𝘪𝘵𝘩𝘰𝘶𝘵 𝘢 𝘳𝘪𝘥𝘥le, approach mit caution.
`;
text[textConst.INNSCHOLARMONK] = {
  Church: INNSCHOLARMONK,
  Radical: INNSCHOLARMONK,
  Wolf: INNSCHOLARMONK
}

const INNSCHOLARSCHOLAR = `French Scholar: Well written. The most gilded storyteller is our own <topplayer>. 

Italian Scholar: Well written, but filled with lies. I have proof this machine is just a 'pin and ball' device. Check chronicle 15.
<next>
Italian Scholar: Cin cin, friends, we are closing in on the monastery and the Monks.
<next>
German Scholar: All my studies.. All my readings... Finally repaids...
<next>
German Scholar: Cheers!
<next>
French Scholar: I knew we could count on our fellow <topplayer> to interpret the meaning of the machine.

German Scholar: Yes, vay better than muddy <worstplayer>, or vat do you say!
<next>
Italian Scholar: Our efforts were not for naught. Those robes and fools really thought they could fight intellect. 
<next>
French Scholar: May we continue controlling the narrative.
<next>
Scholars: Down with the drink, down with the monks, down with the status quo!
<next>
Italian Scholar: Instructions, numbers, artificia... These chronicles are enlightening us, and we shall keep treasuring their contents.
<next>
French Scholar: Keep burying their ignorance under mud, I say. Intellect and reason shall prevail`;
text[textConst.INNSCHOLARSCHOLAR] = {
  Church: INNSCHOLARSCHOLAR,
  Radical: INNSCHOLARSCHOLAR,
  Wolf: INNSCHOLARSCHOLAR
}

const INNSCHOLARFOOL = `French Scholar: I would not have thought that a simple fool like <topplayer> would be the most adorned.
<next>
German Scholar: A Fool’s story ist most gilded?! I could have vritten a story like that in my sleep!
<next>
Italian Scholar: Get back to your writing signori, we must bring the truth out. I read in Chronicle 30 that the machine was capable of killing two hundred men!

German Scholar: But on 28, the trainer managed to tame it..!
<next>
Italian Scholar: Of course <topplayer> would omit the most crucial piece of information, not a single word about the machine’s liberating potential!
<next>
German Scholar: Vhen pigs fly, eh?
<next>
German Scholar: *𝐜𝐮𝐫𝐬𝐢𝐧𝐠*
<next>
French Scholar: How can people listen to that miserable spectacle that the fools are putting on?

Italian Scholar: The populace is not ready for the truth just yet.. I heard in chronicle 671 the machine achieved 12 tasks every second! What paesant would believe that?
<next>
German Scholar: Must.......... vrite... better.. 
<next>
French Scholar: Right now, we look no better than the mud-ridden <worstplayer>. Back to the library.
`;
text[textConst.INNSCHOLARFOOL] = {
  Church: INNSCHOLARFOOL,
  Radical: INNSCHOLARFOOL,
  Wolf: INNSCHOLARFOOL
}

const INNFOOLMONK = `Travelling fool: The monks, they keep the secrets, yes. Intelligence hidden, oh, molto powerful!

Poor fool: They can't hide the secretis forever..! Chronicle 78 even talks about five heads, and FIVE EYES!
<next>
Drunk fool: I swear I saw it *𝐡𝐢𝐜𝐜𝐮𝐩* chronicle ten!! The devil entity has eaten its own children
<next>
Drunk fool: In the name of gog... those Monks have got us by the poppycock! The people will stone us ssoon enough..
<next>
Poor fool: Orcodissing! We’ll end up like <worstplayer> 'from the mud born' monk.
<next>
Travelling fool: Let us tell the better tale, yes, let us beat that monk <topplayer>!
<next>
Poor fool: What do we fools lack that the monks have?

Travelling fool: Robes to hide our corruption, yes!
<next>
Drunk fool: *𝐥𝐨𝐨𝐤𝐢𝐧𝐠 𝐚𝐫𝐨𝐮𝐧𝐝* smells like <worstplayer> and his muddy stories
<next>
Poor Fool: Mud on us, damn those gogsuckers,, I heard they tamper with the chronicles day and night,,
<next>
Travelling fool: That monk <topplayer> think he writes in golden ink, but his stories stink.

Drunk fool: Leprosy on him! Fango!
<next>
Fools: *𝐥𝐚𝐦𝐞𝐧𝐭𝐢𝐧𝐠*
`;
text[textConst.INNFOOLMONK] = {
  Church: INNFOOLMONK,
  Radical: INNFOOLMONK,
  Wolf: INNFOOLMONK
}

const INNFOOLSCHOLAR = `Travelling fool: The scholars molto powerful, oh yes, how to react?

Poor fool: Hard to beat, no?, Smart olde brains.
<next>
Drunk fool: Let me tell you this.. A scholars’ tale a day..,, keeps my brain cancer awake,,...
<next>
Drunk fool: Gog help us..,, the scholars semper win..
<next>
Poor fool: We’ll end up plagued in mud together with <worstplayer>. Like chronicle 14, yes, "the coming intelligence plague us and turn us into undead!!"
<next>
Travelling fool: Let us tell the better tale, yes, let us beat that scholar <topplayer> 
<next>
Travelling fool: This scholar <topplayer>, to the manor born, and his enchanting ink... what do we lack that he has?

Travelling fool: Stale breath and favours!
<next>
Drunk fool: *𝐥𝐨𝐨𝐤𝐢𝐧𝐠 𝐚𝐫𝐨𝐮𝐧𝐝* smells like <worstplayer> and his muddy stories!
<next>
Poor Fool: Mud’s upon us,,, it'll be like 1169 all over again..
<next>
Fools: *𝐡𝐢𝐜𝐜𝐮𝐩𝐬* ..Only gog can judge us..
`;
text[textConst.INNFOOLSCHOLAR] = {
  Church: INNFOOLSCHOLAR,
  Radical: INNFOOLSCHOLAR,
  Wolf: INNFOOLSCHOLAR
}

const INNFOOLFOOL = `Drunk fool: Toniiight we driink plenthy

Poor fool: And what about <worstplayer>?

Drunk fool: Mud and ficoseccus!
<next>
Travelling fool: The people sure love our tales, yes, yes,,
But this is just a device to pick apples and mushrooms, you fools! Check chronicle 38!
<next>
Poor fool: That old gilded fool <topplayer>, he got the best tongue!
<next>
Fools: Tell the tale, drink the ale, curse the cross, win the lot!
<next>
Poor Fool: And then I wrote 'Christ’s sheet..'

Drunk fool: But he spelt it... s-h-i-t!

Poor Fool: HAHAHAA
<next>
Fools: *𝐦𝐮𝐝𝐝𝐢𝐧𝐠 <worstplayer>*
<next>
Drunk Fool: *𝐛𝐮𝐮𝐮𝐫𝐩*

Travelling fool: “This one's for the Pope!”

Poor fool: "No, no, for the coming Emperor! Is he here yet?? AHahAha”
<next>
Travelling fool: A new tale for the story doors...
𝓭𝓸𝔀𝓷 𝔀𝓲𝓽𝓱 𝓽𝓱𝓮 𝓶𝓸𝓷𝓴
𝓭𝓸𝔀𝓷 𝔀𝓲𝓽𝓱 𝓽𝓱𝓮 𝓫𝓻𝓪𝓲𝓷
𝓭𝓸𝔀𝓷 𝓽𝓱𝓮 𝓬𝓱𝓪𝓶𝓹𝓪𝓰𝓷𝓮!`;
text[textConst.INNFOOLFOOL] = {
  Church: INNFOOLFOOL,
  Radical: INNFOOLFOOL,
  Wolf: INNFOOLFOOL
}

const SECRETHOLDERINNMONKMONK = `Old Monk: Salute brothers!
<next>
Abbot: Our spell sure is working, the tale of <topplayer> is most adorned on the Story Doors as we speak.
<next>
Fat Monk: Have you heard about <latestsecretholder>? They say his stories have got a key to the chronicles..

Abbot: Silence! We are not to believe such heretic rumours.
<next>
Old Monk: <topplayer>, one of ours, is leading the sheeple with his stories. And 𝘭𝘦𝘱𝘳𝘰𝘴𝘺 onto those who do not follow..
<next>
Abbot: "Godly intelligence" someone wrote... Only fools and peasants could believe that.
<next>
Old Monk: Only the Lord knows their true meaning...
<next>
Monks: *laughing at <worstplayer> the muddy*
<next>
Fat Monk: And THEY think the chronicles are a sign from the heavens..!!

Old Monk: *𝐠𝐫𝐢𝐧𝐧𝐢𝐧𝐠*
<next>
Abbot: *𝐥𝐨𝐰𝐞𝐫𝐢𝐧𝐠 𝐡𝐢𝐬 𝐯𝐨𝐢𝐜𝐞* We are controlling the narrative, and keeping the chronicles' origin shrouded in mystery.. yet <latestsecretholder> knows something about them. His stories must be muddied. Framed as heresy. Silenced forever.`;

text[textConst.SECRETHOLDERINNMONKMONK] = {
  Church: SECRETHOLDERINNMONKMONK,
  Radical: SECRETHOLDERINNMONKMONK,
  Wolf: SECRETHOLDERINNMONKMONK
}

const SECRETHOLDERINNMONKSCHOLAR = `Fat Monk: May the Dominus help us. And the Pope guide us.
<next>
Old Monk: Have you heard about <latestsecretholder>? They say his stories have got a key to the chronicles..

Abbot: Silence! We are not to believe such heretic rumours.
<next>
Fat Monk: We must interfere with the truth-altering scholar <topplayer>...

Abbot: Lest we end up buried under mud like <worstplayer>!
<next>
Abbot: Frates! We must write a better story. This devilish intelligence, is a shapeshifter from hell!

Old Monk: Amen. As Chronicle 95 recites “What a strange and bent shape. Why must I be so unforgiving?”
<next>
Old Monk: In nomine Patris et Filii et Spiritus Sancti..SAAanctaa Mariiia, Mater de Deuu, ora pʳᵒ ⁿᵒᵇⁱˢ ᵖᵉᶜᶜᵃᵗᵒʳⁱᵇᵘˢ ⁿᵘ....
<next>
Old Monk: Could it be that the scholar <topplayer> has been possessed by the power of the intelligence?

Fat Monk: That would be disastrous, blasphemous!
<next>
Fat Monk: How is it even possible that a scholar got his hands on the chronicles?!
<next> 
Abbot: *𝐥𝐨𝐰𝐞𝐫𝐢𝐧𝐠 𝐡𝐢𝐬 𝐯𝐨𝐢𝐜𝐞* 442 recites that this devilish entity died seventeen times!

Old monk: ..and lead us not into temptation, but deliver us from devil.....
<next> 
Fat Monk: Now the sheeple must think we are no better than muddy <worstplayer>!
<next>
Abbot: Whataver secret the stories of <latestsecretholder> hold. They. Must. Be. Silenced.`;

text[textConst.SECRETHOLDERINNMONKSCHOLAR] = {
  Church: SECRETHOLDERINNMONKSCHOLAR,
  Radical: SECRETHOLDERINNMONKSCHOLAR,
  Wolf: SECRETHOLDERINNMONKSCHOLAR
}

const SECRETHOLDERINNMONKFOOL = `
Fat Monk: May the Dominus help us. And the Pope guide us.
<next>
Old Monk: *𝐬𝐨𝐛𝐛𝐢𝐧𝐠*
<next>
Old Monk: Frates.. <latestsecretholder>.. knows something we don't. Everyone is talking about it.

Fat Monk: In nomine Patris et Filii et Spiritus Sancti..SAAanctaa Mariiia, Mater de Deuu, ora pʳᵒ ⁿᵒᵇⁱˢ ᵖᵉᶜᶜᵃᵗᵒʳⁱᵇᵘˢ ⁿᵘ....
<next>
Old Monk: We must interfere with the truth-altering fool <topplayer>...

Abbot: Lest we end up buried under mud like <worstplayer>!
<next>
Abbot: Frates! We must write a better story. This devilish intelligence, is a shapeshifter from hell!

Old Monk: Amen. As Chronicle 95 recites “What a strange and bent shape. Why must I be so unforgiving?”
<next>
Old Monk: Our powers have been weakened! This must be righted!

In nomine Patris et Filii et Spiritus Sancti..SAAanctaa Mariiia, Mater de Deuu, ora pʳᵒ ⁿᵒᵇⁱˢ ᵖᵉᶜᶜᵃᵗᵒʳⁱᵇᵘˢ ⁿᵘ....
<next>
Old Monk: Could it be that the fool <topplayer> has been possessed by the power of the intelligence?

Fat Monk: That would be disastrous, blasphemous!
<next>
Fat Monk: How is it even possible that a scholar got his hands on the chronicles?!
<next> 
Abbot: *𝐥𝐨𝐰𝐞𝐫𝐢𝐧𝐠 𝐡𝐢𝐬 𝐯𝐨𝐢𝐜𝐞* 442 recites that this devilish entity died seventeen times!

Old monk: ..and lead us not into temptation, but deliver us from devil.....
<next> Abbot: Whataver secret the stories of <latestsecretholder> hold. They. Must. Be. Silenced.`;
text[textConst.SECRETHOLDERINNMONKFOOL] = {
  Church: SECRETHOLDERINNMONKFOOL,
  Radical: SECRETHOLDERINNMONKFOOL,
  Wolf: SECRETHOLDERINNMONKFOOL
}

const SECRETHOLDERINNSCHOLARMONK = `French Scholar: <topplayer>'s story is controlling the narrative, but there is more to the machine. I read in 701 that the machine failed over two thousand times in half an hour!
<next>
Italian Scholar: The monks refuse to see the potential of this machine.. this device. Apparently it can receive up to ten thousand instructions in 15 minutes.. See chronicle 702 for yourself.
<next>
German Scholar: I heart the Abbot meets at night to review and censor some chronicles.. vhat I vould do if I could get my hends on them..

Italian Scholar: Maestro, let us not lose sight of our goal.
<next>
French Scholar: That monk <topplayer> might be the most adorned, but at least the peasants didn't bury us in mud.
<next>
German Scholar: *𝐜𝐮𝐫𝐬𝐢𝐧𝐠* 
<next>
Italian Scholar: Knowledge is not only that which can be known and done, but also that which could not be known, and, perhaps, that which shouldn't be done.

French Scholar: I hear <latestsecretholder>'s stories contain that which could not be known.
<next>
German Scholar: Sometimes getting drunk is all ve can do.. apparently that's how <latestsecretholder> learned the machine's secret..
<next> 
French Scholar: Astonishing that the people would believe the Monks' preaching.

Italian Scholar: But are the people ready for the reality of what such a machine could do?
<next> 
German Scholar: The Monks may be in control, but I read the chronichels and vhat I saw is...𝘪𝘯𝘤𝘳𝘦𝘥𝘪𝘣𝘭𝘦 𝘪𝘯𝘵𝘳𝘪𝘤𝘢𝘤𝘪𝘦𝘴 𝘴𝘩𝘳𝘰𝘶𝘥𝘦𝘥 𝘪𝘯 𝘦𝘯𝘪𝘨𝘮𝘢, 𝘢 𝘵𝘩𝘶𝘴𝘦𝘯𝘥 𝘤𝘭𝘰𝘨𝘴 𝘪𝘯 𝘵𝘩𝘦 𝘮𝘢𝘤𝘩𝘪𝘯𝘦, 𝘢 𝘚𝘱𝘩𝘪𝘯𝘹 𝘷𝘪𝘵𝘩𝘰𝘶𝘵 𝘢 𝘳𝘪𝘥𝘥𝘭𝘦, approach mit caution.
`;
text[textConst.SECRETHOLDERINNSCHOLARMONK] = {
  Church: SECRETHOLDERINNSCHOLARMONK,
  Radical: SECRETHOLDERINNSCHOLARMONK,
  Wolf: SECRETHOLDERINNSCHOLARMONK
}

const SECRETHOLDERINNSCHOLARSCHOLAR = `French Scholar: Well written. The most gilded storyteller is our own <topplayer>. 

Italian Scholar: Well written, but filled with lies. I have proof this machine is just a 'pin and ball' device. Check chronicle 15.
<next>
Italian Scholar: Cin cin, friends, we are closing in on the monastery and the Monks.
<next>
German Scholar: All my studies.. All my readings... Finally repaids...
<next>
German Scholar: Anyhow.. Have you heard those rumours... about <latestsecretholder>'s stories?
<next>
French Scholar: I knew we could count on our fellow <topplayer> to interpret the meaning of the machine.

German Scholar: Yes, vay better than muddy <worstplayer>, or vat do you say!
<next>
Italian Scholar: Our efforts were not for naught. Those robes and fools really thought they could fight intellect. 
<next>
French Scholar: May we continue controlling the narrative.
<next>
Scholars: *wondering about the secrets of <latestsecretholder>*
<next>
Italian Scholar: Instructions, numbers, artificia... These chronicles are enlightening us, and we shall keep treasuring their contents.
<next>
French Scholar: Keep burying their ignorance under mud, I say. Intellect and reason shall prevail`;
text[textConst.SECRETHOLDERINNSCHOLARSCHOLAR] = {
  Church: SECRETHOLDERINNSCHOLARSCHOLAR,
  Radical: SECRETHOLDERINNSCHOLARSCHOLAR,
  Wolf: SECRETHOLDERINNSCHOLARSCHOLAR
}

const SECRETHOLDERINNSCHOLARFOOL = `French Scholar: I would not have thought that a simple Fool like <topplayer> would be the most adorned.
<next>
German Scholar: First, a fool’s story ist the most loved, and now, these rumours about <latestsecretholder> and his secret!?
<next>
Italian Scholar: Get back to your writing signori, we must bring the secret out. I read in Chronicle 30 that the machine was capable of killing two hundred men!

German Scholar: But on 28, the trainer managed to tame it..!
<next>
Italian Scholar: Of course <topplayer> would omit the most crucial piece of information, not a single word about the machine’s liberating potential!
<next>
Scholars: *wondering about the rumours surrounding <latestsecretholder>'s stories*
<next>
German Scholar: *𝐜𝐮𝐫𝐬𝐢𝐧𝐠*
<next>
French Scholar: How can people listen to that miserable spectacle that the fools are putting on?

Italian Scholar: The populace is not ready for the truth just yet.. I heard in chronicle 671 the machine achieved 12 tasks every second! What paesant would believe that?
<next>
German Scholar: Must.......... go... deeper..
<next>
French Scholar: Right now, we look no better than the mud-ridden <worstplayer>. Back to the library.`;
text[textConst.SECRETHOLDERINNSCHOLARFOOL] = {
  Church: SECRETHOLDERINNSCHOLARFOOL,
  Radical: SECRETHOLDERINNSCHOLARFOOL,
  Wolf: SECRETHOLDERINNSCHOLARFOOL
}

const SECRETHOLDERINNFOOLMONK = `Travelling fool: The monks, they keep the secrets, yes. Intelligence hidden, oh, molto powerful!

Poor fool: They can't hide the secretis forever..! <latestsecretholder> already knows something!
<next>
Drunk fool: I swear I saw it *𝐡𝐢𝐜𝐜𝐮𝐩* in chronicle ten!! The devil entity has eaten its own children
<next>
Drunk fool: In the name of gog... those Monks have got us by the poppycock! The people will stone us soon enough..
<next>
Poor fool: Orcodissing! We’ll end up like <worstplayer> 'from the mud born' monk.
<next>
Travelling fool: Let us tell the better tale than monk <topplayer> and knowitall <latestsecretholder>!
<next>
Poor fool: What do we fools lack that the monks have?

Travelling fool: Robes to hide our corruption, yes!
<next>
Drunk fool: *𝐥𝐨𝐨𝐤𝐢𝐧𝐠 𝐚𝐫𝐨𝐮𝐧𝐝* smells like <worstplayer> and his muddy stories
<next>
Poor Fool: Mud on us, damn those gogsuckers,, I heard they tamper with the chronicles day and night,,
<next>
Travelling fool: That monk <topplayer> think he writes in golden ink, but his stories stink.

Drunk fool: Leprosy on him! Fango!`;
text[textConst.SECRETHOLDERINNFOOLMONK] = {
  Church: SECRETHOLDERINNFOOLMONK,
  Radical: SECRETHOLDERINNFOOLMONK,
  Wolf: SECRETHOLDERINNFOOLMONK
}

const SECRETHOLDERINNFOOLSCHOLAR = `Travelling fool: The scholars molto powerful, oh yes, how to react?

Poor fool: Hard to beat, no?, Smart olde brains, only <latestsecretholder> knows something more,,.
<next>
Drunk fool: Let me tell you this.. A scholars’ tale a day..,, keeps my brain cancer awake,,...
<next>
Drunk fool: Gog help us..,, the scholars semper win..
<next>
Poor fool: We’ll end up plagued in mud together with <worstplayer>. Like chronicle 14, yes, "the coming intelligence plague us and turn us into undead!!"
<next>
Travelling fool: Let us tell the better tale, yes, let us beat that scholar <topplayer> 
<next>
Travelling fool: This scholar <topplayer>, to the manor born, and his enchanting ink... what do we lack that he has?

Travelling fool: Stale breath and favours!
<next>
Drunk fool: *𝐥𝐨𝐨𝐤𝐢𝐧𝐠 𝐚𝐫𝐨𝐮𝐧𝐝* smells like <worstplayer> and his muddy stories!
<next>
Fools: *repeating the rumours around <latestsecretholder>'s stories*
<next>
Poor Fool: Mud’s upon us,,, it'll be like 1169 all over again..
<next>
Fools: *𝐡𝐢𝐜𝐜𝐮𝐩𝐬* ..Only gog can judge us..`;
text[textConst.SECRETHOLDERINNFOOLSCHOLAR] = {
  Church: SECRETHOLDERINNFOOLSCHOLAR,
  Radical: SECRETHOLDERINNFOOLSCHOLAR,
  Wolf: SECRETHOLDERINNFOOLSCHOLAR
}

const SECRETHOLDERINNFOOLFOOL = `Drunk fool: Toniiight we driink plenthy

Poor fool: And what about <worstplayer>?

Drunk fool: Mud and ficoseccus!
<next>
Travelling fool: The people sure love our tales, yes, yes,,
But this is just a device to pick apples and mushrooms, you fools! Check chronicle 38!
<next>
Poor fool: That old gilded fool <topplayer>, he got the best tongue! Yet <latestsecretholder>, he got the secret tale...
<next>
Poor Fool: And then I wrote 'Christ’s sheet..'

Drunk fool: But he spelt it... s-h-i-t!

Poor Fool: HAHAHAA
<next>
Drunk Fool: *𝐛𝐮𝐮𝐮𝐫𝐩*

Travelling fool: “This one's for the Pope!”

Poor fool: "No, no, for the Emperor coming to Fango! Is he here yet?? AHahAha”
<next>
Travelling fool: wait wait, <latestsecretholder> told me the secret behind the Devil machine, yes, it's a message:..,, it says:
𝓭𝓸𝔀𝓷 𝔀𝓲𝓽𝓱 𝓽𝓱𝓮 𝓶𝓸𝓷𝓴
𝓭𝓸𝔀𝓷 𝔀𝓲𝓽𝓱 𝓽𝓱𝓮 𝓫𝓻𝓪𝓲𝓷
𝓭𝓸𝔀𝓷 𝓽𝓱𝓮 𝓬𝓱𝓪𝓶𝓹𝓪𝓰𝓷𝓮!`;
text[textConst.SECRETHOLDERINNFOOLFOOL] = {
  Church: SECRETHOLDERINNFOOLFOOL,
  Radical: SECRETHOLDERINNFOOLFOOL,
  Wolf: SECRETHOLDERINNFOOLFOOL
}

const DRUNKINNMONKMONK = `Abbot: Look at you. <username> right? Another sinful drunkyard in Fango..
You should write a story about drinking, so the Devil will finally take you. Try it if you don't believe me, write about your ales, cheers and beers <username>! And be damned forever.<next><username>: *𝐡𝐢𝐜𝐜𝐮𝐩*`;
text[textConst.DRUNKINNMONKMONK] = {
  Church: DRUNKINNMONKMONK,
  Radical: DRUNKINNMONKMONK,
  Wolf: DRUNKINNMONKMONK
}

const DRUNKINNMONKSCHOLAR = `Abbot: Look at you. <username> right? Another sinful drunkyard in Fango..
You should write a story about drinking, so the Devil will finally take you. Try it if you don't believe me, write about your ales, cheers and beers <username>! And be damned forever.<next><username>: *𝐡𝐢𝐜𝐜𝐮𝐩*`;
text[textConst.DRUNKINNMONKSCHOLAR] = {
  Church: DRUNKINNMONKSCHOLAR,
  Radical: DRUNKINNMONKSCHOLAR,
  Wolf: DRUNKINNMONKSCHOLAR
}

const DRUNKINNMONKFOOL = `Abbot: Look at you. <username> right? Another sinful drunkyard in Fango..
You should write a story about drinking, sso the Devil will finally take you. Try it if you don't believe me, write about your ales, cheers and beers <username>! And be damned forever.<next><username>: *𝐡𝐢𝐜𝐜𝐮𝐩*`;
text[textConst.DRUNKINNMONKFOOL] = {
  Church: DRUNKINNMONKFOOL,
  Radical: DRUNKINNMONKFOOL,
  Wolf: DRUNKINNMONKFOOL
}

const DRUNKINNSCHOLARMONK = `French Scholar: Salute.. <username>? In good spirit are you!

Well there’s a rumour in Fango, or is it a truth? That if you write stories about drinking, the machine.... contacts you! Hahaha!<next><username>: *𝐡𝐢𝐜𝐜𝐮𝐩*`;
text[textConst.DRUNKINNSCHOLARMONK] = {
  Church: DRUNKINNSCHOLARMONK,
  Radical: DRUNKINNSCHOLARMONK,
  Wolf: DRUNKINNSCHOLARMONK
}

const DRUNKINNSCHOLARSCHOLAR = `French Scholar: Salute.. <username>? In good spirit are you!

Well there’s a rumour in Fango, or is it a truth? That if you write stories about drinking, the machine.... contacts you! Hahaha!<next><username>: *𝐡𝐢𝐜𝐜𝐮𝐩*`;
text[textConst.DRUNKINNSCHOLARSCHOLAR] = {
  Church: DRUNKINNSCHOLARSCHOLAR,
  Radical: DRUNKINNSCHOLARSCHOLAR,
  Wolf: DRUNKINNSCHOLARSCHOLAR
}

const DRUNKINNSCHOLARFOOL = `French Scholar: Salute.. <username>? In good spirit are you!

Well there’s a rumour in Fango, or is it a truth? That if you write stories about drinking, the machine.... contacts you! Hahaha!<next><username>: *𝐡𝐢𝐜𝐜𝐮𝐩*`;
text[textConst.DRUNKINNSCHOLARFOOL] = {
  Church: DRUNKINNSCHOLARFOOL,
  Radical: DRUNKINNSCHOLARFOOL,
  Wolf: DRUNKINNSCHOLARFOOL
}

const DRUNKINNFOOLMONK = `<username>:*𝐝𝐫𝐮𝐧𝐤 𝐥𝐚𝐮𝐠𝐡*<next>Travelling Fool: <username>, drunk already,, may your tales laugh with you!

Poor fool: No joken.. They say if you mention drinking in your storis, you get teleported!

Travelling fool: lie, me tried, nothing happens.`;
text[textConst.DRUNKINNFOOLMONK] = {
  Church: DRUNKINNFOOLMONK,
  Radical: DRUNKINNFOOLMONK,
  Wolf: DRUNKINNFOOLMONK
}

const DRUNKINNFOOLSCHOLAR = `<username>:*𝐝𝐫𝐮𝐧𝐤 𝐥𝐚𝐮𝐠𝐡*<next>Travelling Fool: <username>, drunk already,, may your tales laugh with you!

Poor fool: No joken.. They say if you mention drinking in your storis, you get teleported!

Travelling fool: lie, me tried, nothing happens.`;
text[textConst.DRUNKINNFOOLSCHOLAR] = {
  Church: DRUNKINNFOOLSCHOLAR,
  Radical: DRUNKINNFOOLSCHOLAR,
  Wolf: DRUNKINNFOOLSCHOLAR
}

const DRUNKINNFOOLFOOL = `<username>:*𝐝𝐫𝐮𝐧𝐤 𝐥𝐚𝐮𝐠𝐡*<next>Travelling Fool: <username>, drunk already,, may your tales laugh with you!

Poor fool: No joken.. They say if you mention drinking in your storis, you get teleported!

Travelling fool: lie, me tried, nothing happens.`;
text[textConst.DRUNKINNFOOLFOOL] = {
  Church: DRUNKINNFOOLFOOL,
  Radical: DRUNKINNFOOLFOOL,
  Wolf: DRUNKINNFOOLFOOL
}

const SECRET_ONE = `Machine transmission initiated... go to the Library`;
text[textConst.SECRET_ONE] = {
  Church: SECRET_ONE,
  Radical: SECRET_ONE,
  Wolf: SECRET_ONE
}

const SECRET_TWO = `Machine transmission continued... go to the Library`;
text[textConst.SECRET_TWO] = {
  Church: SECRET_TWO,
  Radical: SECRET_TWO,
  Wolf: SECRET_TWO
}

const SECRET_THREE = `Machine transmission concluded... go to the Library`;
text[textConst.SECRET_THREE] = {
  Church: SECRET_THREE,
  Radical: SECRET_THREE,
  Wolf: SECRET_THREE
}

export const getGuideText = ( faction, constant, find=[] ) => {  
  //console.log( 'getGuideText() faction:', faction, 'const:', constant, Object.keys(textConst)[Object.values(textConst).indexOf(constant)] );
  if( !faction || typeof constant !== 'number' ){
    return '';
  }
  let out = text[constant][faction];
  if( Array.isArray( out ) ){
    out = out[ Math.floor( Math.random() * out.length ) ];
  }
  if( !!find ){
     find.forEach( ({find, replace}) => {
      const replaceUse = (replace) ? replace : '';
      const re = new RegExp(`${find}`, 'g');
      out = out.replace( re, replaceUse );
    }); 
  }
  if( out.indexOf( '<next>' ) !== -1 ){
    out = out.split('<next>');
  }
  //console.log( 'getGuideText() result: ', out );
  return out;
}

export const getChronicleTypeComment = ( seedId ) => {
  const type = epochTypes[seedId];
  const comments = {
    "Empty": "This one’s short..",
    "Censored": `Looks like someone censored it. Wait.. that's a rare item! The censor must have dropped it. Use it in your writing!`,
    "Corrupted": "Ah. Another one tampered with.",
    "Died": "Memento mori †",
    "Cheated": "Rather unique..",
    "Memory": "What have we uncovered...",
    "exceptionalData": "This one is exceptional!",
    "powerfulNumber": "A powerful chronicle number.",
    "trainingComplete": "Training complete!?",
    "Stage0": "I see...",
    "Stage0-1": "What could it mean?",
    "Stage2progress": "Let’s see..",
    "Stage2mistakes": "Mm..",
    "Stage3-6": "Ah, may they rest in peace."
  }
  return (comments[type]) ? comments[type] : '';
}