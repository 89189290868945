import MainButton from "./MainButton"

export const ShowSecret = ({ text="", onClose=()=>{} }) => {
  const textHasEpochsUrl = (text.indexOf('GODMODEEPOCHS.COM') > -1);
  const textInUse = (textHasEpochsUrl) ? text.replace('GODMODEEPOCHS.COM', '') : text;
  return <div className="roll-secret">
    <div className="roll-secret--content fango-border">
      {textInUse}
      {(textHasEpochsUrl) ? <a href="https://godmodeepochs.com" target="_blank" style={{color: 'inherit'}}>𝗖𝗟𝗜𝗖𝗞 𝗧𝗢 𝗘𝗡𝗧𝗘𝗥</a> : '' }
    </div>          
    <MainButton name="Close chronicle" onClick={onClose}/>
</div>
}